<template>
  <v-container>
    <v-row v-if="pageIsLoading" justify="center">
      <v-col xs="12" xl="8">
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-row v-if="!pageIsLoading" justify="center">
      <v-col xs="12" xl="8">
        <v-row>
          <v-col>
            <h1>Manage Stages</h1>

            <p class="grey--text">
              Here you can create, update and delete a stage. You can also
              change the order of the stages by dragging the rows.
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-list class="pt-5 rounded-lg">
              <label class="mx-5 text-h6">Stage List</label>

              <v-icon
                class="float-right mr-5"
                title="Create new stage"
                @click="
                  $router.push({
                    path: `/dashboard/admin/kanban/manage_stages/add/${type}`,
                  })
                "
              >
                mdi-plus-circle-outline
              </v-icon>

              <v-divider class="mt-5 mb-8" />

              <draggable
                :list="stageData"
                group="my-group"
                @change="changeOrdinalNumber($event)"
              >
                <v-list-item
                  v-for="stage in stageData"
                  :key="stage.id"
                  class="mx-5 my-2 rounded-lg"
                  style="border: 2px solid #333; cursor: pointer"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ stage.stage_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ stage.stage_description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          mdi-dots-vertical
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title
                            @click="
                              $router.push({
                                path: `/dashboard/admin/kanban/manage_stages/update/${type}/${stage.id}`,
                              })
                            "
                            class="d-flex align-center"
                            style="cursor: pointer"
                          >
                            <v-icon class="text-h6 mr-2">
                              mdi-pencil-outline
                            </v-icon>
                            Edit
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-title
                            @click="deleteStage(stage)"
                            class="d-flex align-center"
                            style="cursor: pointer"
                          >
                            <v-icon class="text-h6 mr-2">
                              mdi-trash-can-outline
                            </v-icon>
                            Delete
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-icon>
                </v-list-item>
              </draggable>
            </v-list>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              @click="$router.go(-1)"
            >
              Back
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },

  props: ["type"],

  data: () => ({
    pageIsLoading: true,

    stageData: [],
  }),

  methods: {
    async changeOrdinalNumber(event) {
      try {
        const res = await API().post("api/kanban/update_ordinal_number_stage", {
          old_number: event.moved.oldIndex,
          new_number: event.moved.newIndex,
          stage: event.moved.element,
          type: this.type,
        });

        console.log(res);
        this.getStages();
      } catch (err) {
        console.log(err);
      }
    },

    async deleteStage(stage) {
      try {
        if (window.confirm("Are you sure you want to delete this stage?")) {
          const res = await API().post("api/kanban/delete_stage", {
            stage_data: stage,
            type: this.type,
          });

          if (res.status == 204) {
            alert("Unable to delete stage because it is currently being used.");
          }

          if (res.status == 200) {
            console.log(res);

            this.getStages();
          }
        } else {
          console.log("delete has been canceled");
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getStages() {
      try {
        switch (this.type) {
          case "Deal": {
            const res_deal = await API().get(`api/kanban/get_kanban_deal_data`);
            this.stageData = res_deal.data;

            this.pageIsLoading = false;
            break;
          }

          case "Lender on boarding": {
            const res_lender = await API().get(
              `api/kanban/get_kanban_lender_data`
            );
            this.stageData = res_lender.data;
            this.pageIsLoading = false;
            break;
          }

          default: {
            console.log("type is not valid");
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },

  mounted() {
    this.getStages();
  },
};
</script>
